/* eslint-disable no-unreachable */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  Button,
  Form,
  TextArea,
  Card,
  Space,
  AutoCenter,
  SafeArea,
} from 'antd-mobile'
import PropTypes from 'prop-types'

import BaseHeader from 'components/Layout/Header'

const MobileView = ({ intl, loading, onSubmit }) => {
  const [form] = Form.useForm()

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <SafeArea position="top" />
      </div>
      <div
        style={{
          flex: 0,
          borderBottom: 'solid 1px var(--adm-color-border)',
        }}
      >
        <BaseHeader
          showCollapse={false}
          showLogbook={false}
          showAlert={false}
          showUserProfile={false}
          headerTitle={intl.formatMessage({
            id: 'breadcrumbs.expired.title',
          })}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'auto',
          background: '#fafbfc',
        }}
      >
        <Card>
          <AutoCenter>
            <Space direction="vertical" style={{ width: '100%' }}>
              <h2 style={{ margin: 0, textAlign: 'center' }}>
                <FormattedMessage id="expired.title" />
              </h2>
              <p style={{ textAlign: 'center' }}>
                <FormattedMessage id="expired.paragragh" />
              </p>

              <Form
                form={form}
                onFinish={onSubmit}
                layout="vertical"
                style={{ width: '100%' }}
                footer={
                  <Button
                    block
                    type="submit"
                    color="primary"
                    loading={loading}
                    loadingText={intl.formatMessage({
                      id: 'global.loading.messages.loading',
                    })}
                  >
                    <FormattedMessage id="global.button.validateKey" />
                  </Button>
                }
              >
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'global.labels.requiredField',
                      }),
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder={intl.formatMessage({ id: 'expired.key' })}
                  />
                </Form.Item>
              </Form>
            </Space>
          </AutoCenter>
        </Card>
      </div>
      <div>
        <SafeArea position="bottom" />
      </div>
    </div>
  )
}

MobileView.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  contract: PropTypes.shape({
    expiredDate: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

MobileView.defaultProps = {
  contract: {
    expiredDate: null,
  },
}

export default MobileView
