import React from 'react'
import ReactDOM from 'react-dom'

import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Toast } from 'antd-mobile'
import axios from 'axios'

import api from 'services/api'

import App from './App'
import * as serviceWorker from './serviceWorker'

CapacitorUpdater.notifyAppReady()
CapacitorUpdater.addListener('download', info => {
  Toast.show({
    content: `Downloading ${info.percent}%`,
    duration: 0,
    maskClickable: false,
  })
})
CapacitorApp.addListener('appStateChange', async state => {
  if (state.isActive) {
    try {
      const response = await api.get('/miscellaneous/signals/healthcheck')
      const newVersion = response.data?.version
      const list = await CapacitorUpdater.list()
      const appInfo = await CapacitorUpdater.current()
      const currentVersion =
        appInfo.bundle.version === 'builtin'
          ? appInfo.native
          : appInfo.bundle.version

      if (newVersion && newVersion !== currentVersion) {
        const alreadyDownloadedBundle = list.bundles.find(
          bundle => bundle.version === newVersion && bundle.status === 'pending'
        )

        let newBundleVersion = null

        if (alreadyDownloadedBundle) {
          newBundleVersion = alreadyDownloadedBundle.id
        } else {
          const fileUrl = `https://intelup-version.s3.amazonaws.com/hermes/${newVersion}/com.intelup.mobile.zip`

          try {
            const hasFileResponse = await axios.head(fileUrl)
            if (hasFileResponse.status === 200) {
              // Ensure download occurs while the app is active, or download may fail
              newBundleVersion = await CapacitorUpdater.download({
                version: newVersion,
                url: fileUrl,
              })
            } else {
              console.log('File not accessible at the URL, skipping download.')
            }
          } catch (error) {
            console.log('Error accessing file at the URL:', error.message)
          }
        }

        if (newBundleVersion) {
          SplashScreen.show()
          try {
            await CapacitorUpdater.set(newBundleVersion)
            // At this point, the new version should be active, and will need to hide the splash screen
            SplashScreen.hide()
          } catch (Exception) {
            console.log(Exception)
            SplashScreen.hide() // Hide the splash screen again if something went wrong
          }
        }
      }
    } catch (error) {
      console.error('Healthcheck failed:', error)
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
